/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* purgecss end ignore */

body {
  @apply bg-gray-100 text-gray-900;
}

.btn {
  @apply py-2 px-4 rounded border border-transparent text-white font-medium transition duration-150 ease-in-out;
  user-select: none;
}

.btn:focus {
  @apply outline-none shadow-outline;
}

.btn:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.btn-indigo {
  @apply bg-indigo-600;
}

.btn-indigo:hover {
  @apply bg-indigo-500;
}

.btn-indigo:focus {
  @apply border-indigo-700;
}

.btn-indigo:active {
  @apply bg-indigo-700;
}

.btn-red {
  @apply bg-red-600;
}

.btn-red:hover {
  @apply bg-red-500;
}

.btn-red:focus {
  @apply border-red-700;
}

.btn-red:active {
  @apply bg-red-700;
}

.btn-blue {
  @apply bg-blue-600;
}

.btn-blue:hover {
  @apply bg-blue-500;
}

.btn-blue:focus {
  @apply border-blue-700;
}

.btn-blue:active {
  @apply bg-blue-700;
}

.btn-indigo-secondary {
  @apply text-indigo-700 bg-indigo-100 border-indigo-200;
}

.btn-indigo-secondary:hover {
  @apply text-indigo-600;
}

.btn-indigo-secondary:focus {
  @apply border-indigo-300;
}

.btn-orange {
  @apply bg-orange-600;
}

.btn-orange:hover {
  @apply bg-orange-500;
}

.btn-orange:focus {
  @apply border-orange-700;
}

.btn-orange:active {
  @apply bg-orange-700;
}

.btn-orange-secondary {
  @apply text-orange-700 bg-orange-200 border border-orange-300;
}

.btn-orange-secondary:hover {
  @apply text-orange-800 bg-orange-300;
}

.btn-orange-secondary:focus {
  @apply border-orange-300;
}

.btn-purple {
  @apply bg-purple-600;
}

.btn-purple:hover {
  @apply bg-purple-500;
}

.btn-purple:focus {
  @apply border-purple-700;
}

.btn-purple:active {
  @apply bg-purple-700;
}

.btn-purple-secondary {
  @apply text-purple-700 bg-purple-200 border border-purple-300;
}

.btn-purple-secondary:hover {
  @apply text-purple-800 bg-purple-300;
}

.btn-purple-secondary:focus {
  @apply border-purple-300;
}

.btn-green {
  @apply bg-green-600;
}

.btn-green:hover {
  @apply bg-green-500;
}

.btn-green:focus {
  @apply border-green-700;
}

.btn-green:active {
  @apply bg-green-700;
}

.btn-green-secondary {
  @apply text-green-700 bg-green-200 border border-green-300;
}

.btn-green-secondary:hover {
  @apply text-green-800 bg-green-300;
}

.btn-green-secondary:focus {
  @apply border-green-300;
}

.btn-yellow {
  @apply bg-yellow-600;
}

.btn-yellow:hover {
  @apply bg-yellow-500;
}

.btn-yellow:focus {
  @apply border-yellow-700;
}

.btn-yellow:active {
  @apply bg-yellow-700;
}

.btn-yellow-secondary {
  @apply text-yellow-700 bg-yellow-200 border border-yellow-300;
}

.btn-yellow-secondary:hover {
  @apply text-yellow-800 bg-yellow-300;
}

.btn-yellow-secondary:focus {
  @apply border-yellow-300;
}

.pulsate {
  animation: pulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.2;
}

@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.pulsate-fast {
  animation: pulsate-fast 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.05;
}

@keyframes pulsate-fast {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.05;
  }
}

.shake {
  animation: shake-animation 0.5s linear;
}

@keyframes shake-animation {
  8%,
  41% {
    transform: translateX(-10px);
  }
  25%,
  58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}

.progress-indicator {
  height: 5px;
  position: relative;
  overflow: hidden;
}

.progress-indicator span {
  display: block;
  height: 100%;
}

.progress-bar {
  animation: progress-bar-animation 5s ease-in-out;
  animation-fill-mode: both;
}

@keyframes progress-bar-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@import 'tailwindcss/utilities';
